var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box__container-wrapper"},[_c('div',{staticClass:"box__img-wrapper",class:{
            'box__img-wrapper--h': _vm.RBox,
            'box__img-wrapper--v': _vm.SBox,
            circle: _vm.rounded,
        }},[_c('img',{staticClass:"box__img",class:{ circle: _vm.rounded },attrs:{"src":_vm.img
                    ? _vm.img
                    : require('../../assets/images/defaults/default_product.jpg')}})]),_c('div',{staticClass:"box__body",class:{ alignCenter: _vm.alignCenter }},[(_vm.heading)?_c('h1',{staticClass:"box__body-heading",class:{
                'box__body-heading--bold': _vm.hBold,
                'box__body-heading--lg': _vm.hSizeLg,
                'box__body-heading--sm': _vm.hSizeSm,
            }},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_vm._e(),(_vm.subHeading)?_c('p',{staticClass:"box__body-subheading",class:{ 'box__body-subheading--sm': _vm.shSizeSm }},[(_vm.prefix)?_c('span',{class:{ 'box__body-subheading--bold': _vm.pBold }},[_vm._v(_vm._s(_vm.prefix)+" ")]):_vm._e(),(_vm.subHeading)?_c('span',{class:{ 'box__body-subheading--bold': _vm.shBold }},[_vm._v(_vm._s(_vm.subHeading))]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }