<template>
    <div
        :id="groupId + '-' + item.type"
        class="border-b-2 border-gray-200"
        :class="{ 'is-active': itemActive }"
    >
        <div class="relative">
            <button
                @click="toggle"
                class="w-full text-left bg-transparent border-none p-5 text-lg font-bold"
            >
                <h4 class="accordion-item-title-text">{{ item.type }}</h4>
                <span class="accordion-item-trigger-icon"></span>
            </button>
        </div>
        <transition
            name="accordion-item"
            @enter="startTransition"
            @after-enter="endTransition"
            @before-leave="startTransition"
            @after-leave="endTransition"
        >
            <div v-if="itemActive" class="px-5 overflow-hidden bg-white">
                <!-- <div class="py-2">
                            {{item.description}}
                    </div> -->
                <div v-if="subContent" class="py-2">
                    <div
                        v-for="(content, index) in subContent"
                        :key="index + '_' + content.subtask"
                        class="flex justify-between py-2"
                        style="flex-direction: column;"
                    >
                        <div
                            class=""
                            style="display: flex; justify-content: space-between;"
                        >
                            <div class="font-bold">
                                {{ content.name }}
                            </div>
                            <div class="">
                                &#8377;
                                {{ convertToIndianNumberSystem(content.price) }}
                            </div>
                        </div>

                        <div class="">
                            {{ content.description }}
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import ConvertToPrice from 'mixins/ConvertToPrice'

export default {
    name: 'Accordion',
    props: ['item', 'multiple', 'groupId', 'active', 'subContent'],
    mixins: [ConvertToPrice],
    data() {
        return {
            itemActive: this.active,
        }
    },
    methods: {
        toggle(event) {
            if (this.multiple) {
                this.itemActive = !this.itemActive
            } else {
                this.$parent.$children.forEach(item => {
                    if (
                        item.$el.id ===
                        event.currentTarget.parentElement.parentElement.id
                    ) {
                        item.itemActive = !item.itemActive
                    } else {
                        item.itemActive = false
                    }
                })
            }
        },

        startTransition(el) {
            el.style.height = el.scrollHeight + 'px'
        },

        endTransition(el) {
            el.style.height = ''
        },
    },
}
</script>
<style lang="scss">
.accordion-item-trigger-icon {
    $size: 8px;
    display: block;
    position: absolute;
    top: 0;
    right: 1.25rem;
    bottom: 0;
    margin: auto;
    width: $size;
    height: $size;
    border-right: 2px solid #363636;
    border-bottom: 2px solid #363636;
    transform: translateY(-$size / 4) rotate(45deg);
    transition: transform 0.2s ease;

    .is-active & {
        transform: translateY($size / 4) rotate(225deg);
    }
}

.accordion-item-enter-active,
.accordion-item-leave-active {
    will-change: height;
    transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
    height: 0 !important;
}
</style>
