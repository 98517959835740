import { getCustomScene, getMoreInspirations, switchSceneImage } from 'api/scenesApi'
import { getAlternateProducts, getProduct } from 'api/productsApi'
import _ from "lodash"

const CustomScenePage = {
    state: () => ({
        customScenes: {},
        cloneSceneData:[],
        alternateProducts: {
            status: false,
            data: []
        },
        cartProducts: {},
        replacedProducts: {},
        totalCartValue: 0,
        moreInspirations: [],
        showCart: false,
        productDetail:{},
    }),

    mutations: {
        setCustomScene(state, payload){
            state.customScenes = payload
        },
        setUpdateCustomScene(state,payload){
            state.customScenes = payload
        },
        setCloneSceneData(state,payload){
            state.cloneSceneData = payload
        },
        setAlternateProducts(state, payload){
            state.alternateProducts.data = payload
        },
        setAlternateProductsStatus(state, status){
            state.alternateProducts.status = status
        },  
        setCartProducts(state, payload){
            state.cartProducts = payload
        },
        setReplacedProducts(state, payload){
            state.replacedProducts = payload
        },
        setTotalCartValue(state, payload){
            state.totalCartValue = payload
        },
        setMoreInspirations(state, payload){
            state.moreInspirations = payload
        },
        setShowCart(state){
            state.showCart = !state.showCart
        },
        setCurrentSceneProducts(state, payload){
            state.currentSceneProducts = payload
        },
        setProductDetail(state, payload){
            state.productDetail = payload
        }
    },

    getters: {
        totalCartProducts: state => {
            return Object.keys(state.cartProducts).length
        }
    },

    actions: {
        fetchCustomScene({commit}, id){
            return getCustomScene(id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setCustomScene', payload)
                        commit('setCartProducts', payload)
                        return payload
                    }
                })
        },
        cloneData({commit}, scene_data){
            let updateScene = _.cloneDeep(scene_data)
            commit('setCloneSceneData',updateScene)
        },
        updateCloneData({commit}, newData){
            commit('setCloneSceneData',newData)
        },
        fetchUpdateScene({commit,state},{body, sceneIndex, productIndex}){
            return switchSceneImage(body)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        let updateScene = _.cloneDeep(state.cloneSceneData)
                        if(payload.status==true){
                            updateScene[sceneIndex].img_array[0] = payload.switch_list[0].image
                        } 
                        if(payload.switch_list[0].replaced_product_details.product_type=="furnish"){
                            updateScene[sceneIndex].furnishing_products_in_scene[productIndex] = payload.switch_list[0].replaced_product_details
                        }else{
                            updateScene[sceneIndex].decor_products_in_scene[productIndex] = payload.switch_list[0].replaced_product_details
                        }
                        commit('setCloneSceneData', updateScene)
                    }
                })
        },
        fetchAlternateProducts({commit}, {scene_id, x_loc, y_loc}){
            return getAlternateProducts(scene_id, x_loc, y_loc)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setAlternateProductsStatus', true)
                        commit('setAlternateProducts', payload.rel_product_data)
                        return payload
                    }
                })
        },
        fetchMoreInspirations({commit}, scene_id){
            return getMoreInspirations(scene_id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setMoreInspirations', payload.scene_detail)
                        return payload
                    }
                })
        },
        clearAlternateProducts({commit}){
            commit('setAlternateProductsStatus', false)
            commit('setAlternateProducts', [])
        },
        CartProducts({commit}, payload){
            commit('setCartProducts', payload)
            let price=0
            for (var key in payload) {
                let payload_price
                if(payload[key].item_price){
                    payload_price=payload[key].item_price
                }else{
                    payload_price=payload[key].base_price
                }
                price+=payload_price
            }
            commit('setTotalCartValue',price)
            
        },
        replacedProducts({commit}, payload){
            commit('setReplacedProducts', payload)
        },
        calTotalCartValue({commit}, payload){
            commit('setTotalCartValue', payload)
        },
        toggleCart({commit}, toggle){
            commit('setShowCart', toggle)
            if(toggle){
                document.querySelector("body").classList.add("overflow-hidden");
            }else{
                document.querySelector("body").classList.remove("overflow-hidden");
            }
        },
        changeCurrentSceneProducts({commit},payload){
            console.log('changed')
            commit('setCurrentSceneProducts', payload)
        },
        fetchProductDetail({commit},product_id){  
            console.log(product_id)
            return getProduct(product_id)
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        console.log(payload)
                        commit('setProductDetail', payload)
                        return payload
                    }
                })
        },
        clearProductDetail({commit}){  
            commit('setProductDetail',{})
        }
    }
}

export default CustomScenePage;