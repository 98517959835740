<template>
    <div
        class="custom-select  rounded-md text-sm"
        :tabindex="tabindex"
        @blur="open = false"
    >
        <div
            class="selected border-2 border-gray-400 rounded-md cursor-pointer flex items-center"
            :class="{ open: open }"
            @click="open = !open"
        >
            <div class="w-5/6 flex justify-between">
                <span>
                    {{ selected.room }}
                </span>
                <span>
                    &#8377; {{ convertToIndianNumberSystem(selected.price) }}
                </span>
            </div>
            <svg
                class="h-8 mx-auto w-1/6 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                />
            </svg>
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div
                class="flex justify-between px-4"
                v-for="(option, i) in options"
                :key="i"
                @click="selectedRoom(option, i)"
            >
                <p v-if="selected.index != i">{{ option.room_type }}</p>
                <p v-if="selected.index != i">
                    &#8377; {{ convertToIndianNumberSystem(option.price) }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import ConvertToPrice from 'mixins/ConvertToPrice'

export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: Object,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    mixins: [ConvertToPrice],
    data() {
        return {
            selected: {
                index: this.default ? this.default.index : 1,
                room: this.default
                    ? this.default.scene.room_type
                    : this.options.length > 0
                    ? this.options[0].room_type
                    : null,
                price: this.default
                    ? this.default.scene.price
                    : this.options.length > 0
                    ? this.options[0].price
                    : null,
            },
            open: false,
        }
    },
    mounted() {
        this.$emit('selected', this.selected)
    },
    methods: {
        selectedRoom(selected, index) {
            this.selected.index = index
            this.selected.room = selected.room_type
            this.selected.price = selected.price
            this.open = false
            this.$emit('selected', this.selected)
        },
    },
}
</script>
<style scoped>
.custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
}

.custom-select .selected {
    background-color: #fff;
    color: black;
    padding-left: 1em;
    user-select: none;
}

.custom-select .selected.open {
    border-radius: 6px 6px 0px 0px;
}

/* .custom-select .selected .icon:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: black transparent transparent transparent;
} */

.custom-select .items {
    color: black;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    border-right: 2px solid #9ca3af;
    border-left: 2px solid #9ca3af;
    border-bottom: 2px solid #9ca3af;
    position: absolute;
    background-color: #ffff;
    left: 0;
    right: 0;
    z-index: 1;
}

.custom-select .items div {
    color: black;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .items div:hover {
    background-color: #d1d5db;
}

.selectHide {
    display: none;
}
</style>
