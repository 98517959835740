<template>
    <div>
        <div class="flex">
            <div class="relative w-4/6">
                <img class="mx-auto" :src="sceneImage ? sceneImage : ''" />
                <div
                    v-if="scene_image_array.length > 1"
                    class=" p-2 absolute cursor-pointer rounded-md bg-white top-1 right-1"
                    @click="changeView(sceneImage)"
                >
                    <img src="@/assets/images/custom_scene/ic-view.svg" />
                </div>
                <div
                    v-for="(product, index) in currentSceneProducts"
                    :key="index + '_' + product.product_id"
                    class="circle-outer"
                >
                    <div
                        v-if="
                            product.is_replaceable == true &&
                                product.is_duplicate == false
                        "
                        class="absolute cursor-pointer circle-add"
                        :style="{
                            left: product.xperc * 100 + '%',
                            top: product.yperc * 100 + '%',
                            transform: 'translate(-50%,-50%)',
                            zIndex: '1',
                        }"
                        :id="product.product_id"
                    >
                        <div class="add">+</div>
                    </div>
                    <div
                        class="circle-add-menu absolute"
                        :style="{
                            left: product.xperc * 100 + '%',
                            top: product.yperc * 100 + '%',
                            transform: 'translate(-50%,-50%)',
                            zIndex: '1',
                        }"
                    >
                        <div class="loc-info">
                            <font-awesome-icon
                                :icon="['fas', 'info-circle']"
                                @click="locProductModal(product.product_id)"
                            />
                        </div>
                        <div
                            class="loc-replace"
                            @click="
                                alternateProduct(
                                    index,
                                    product.product_type,
                                    currentSceneId,
                                    product.xperc,
                                    product.yperc,
                                )
                            "
                        >
                            <font-awesome-icon :icon="['fas', 'sync-alt']" />
                        </div>
                        <div
                            class="loc-remove"
                            @click="
                                removeProduct(
                                    index,
                                    product.product_id,
                                    product.product_type,
                                )
                            "
                        >
                            <font-awesome-icon :icon="['fas', 'trash']" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-2/6 p-3">
                <div
                    class="border-2 border-gray-200 rounded-md flex flex-wrap p-4 text-xs"
                >
                    <div
                        class="w-1/3 text-center mb-1 cursor-pointer"
                        @click="moreInspirationsModal"
                    >
                        <img
                            class="mx-auto"
                            src="@/assets/images/custom_scene/theme.png"
                        />
                        <p class="buttongroup_title">More Inspiration</p>
                    </div>
                    <div
                        class="w-1/3 text-center mb-1 cursor-pointer"
                        @click="openVirtualTour(vt_url)"
                    >
                        <img
                            class="mx-auto"
                            src="@/assets/images/custom_scene/vt.png"
                        />
                        <p class="buttongroup_title">Virtual Tour</p>
                    </div>
                    <div class="w-1/3 text-center mb-1 cursor-pointer">
                        <img
                            class="mx-auto"
                            src="@/assets/images/custom_scene/vt.png"
                        />
                        <p class="buttongroup_title">Virtual Tour</p>
                    </div>
                    <div class="w-1/3 text-center mb-1 cursor-pointer">
                        <img
                            class="mx-auto"
                            src="@/assets/images/custom_scene/light.png"
                        />
                        <p class="buttongroup_title">Lights On</p>
                    </div>
                    <div class="w-1/3 text-center mb-1 cursor-pointer">
                        <img
                            class="mx-auto"
                            src="@/assets/images/custom_scene/draft.png"
                        />
                        <p class="buttongroup_title">Save as Draft</p>
                    </div>
                    <div class="w-1/3 text-center mb-1 cursor-pointer">
                        <img
                            class="mx-auto"
                            src="@/assets/images/custom_scene/share.png"
                        />
                        <p class="buttongroup_title">Share</p>
                    </div>
                </div>
                <div class="">
                    <div class="py-1">
                        <p for="selected-rooms" class="text-gray-500">
                            Selected room
                        </p>
                    </div>
                    <CustomSelect
                        v-if="scene_data"
                        :options="scene_data"
                        :default="defaultSelect"
                        @selected="currentScene"
                    />
                </div>
                <div class="my-2">
                    <div class="py-1">
                        <p class="text-gray-500">Saved Drafts</p>
                    </div>
                    <div class="flex flex-wrap text-sm">
                        <span
                            class="rounded-full p-1 text-white mr-1 mb-1"
                            style="background-color: #ff6100;"
                            >Master room - Bedroom 1</span
                        >
                    </div>
                </div>
                <div
                    class="flex border-2 border-gray-400 rounded-lg justify-between px-2 py-2 text-sm"
                >
                    <span>Total Cart Value</span>
                    <span class="sr-total-product"
                        >{{ totalCartProducts }} Products(View)</span
                    >
                    <span
                        >&#8377;
                        {{ convertToIndianNumberSystem(totalCartValue) }}</span
                    >
                </div>
                <Button class="my-2 w-full text-sm" @click.native="toggleCart">
                    Proceed to Checkout
                </Button>
            </div>
        </div>
        <div
            v-if="alternateProducts.status"
            class="border-0 "
            style="background-color: #f6f6f6;"
        >
            <div v-if="alternateProducts.data.length != 0" class="flex p-4">
                <div
                    class="alternateproduct-hide "
                    @click="hideAlternateProduct"
                >
                    <font-awesome-icon :icon="['fas', 'times']" />
                </div>
                <div
                    class="flex py-4 overflow-y-auto alternate-products_section"
                >
                    <div
                        v-for="alternateProduct in alternateProducts.data"
                        :key="alternateProduct.product_id"
                    >
                        <div
                            :id="alternateProduct"
                            class="relative rounded-lg cursor-pointer mr-2 h-24 w-24 border-2"
                            :class="
                                alternateProduct.original_product == true
                                    ? 'border-green-500'
                                    : ''
                            "
                        >
                            <img
                                @click="
                                    changeProductInScene(
                                        alternateProduct,
                                        currentSceneProducts,
                                        currentProductIndex,
                                        currentSceneIndex,
                                    )
                                "
                                :src="
                                    alternateProduct.image
                                        ? alternateProduct.image
                                        : require('assets/images/custom_scene/bed.png')
                                "
                                :alt="alternateProduct.name"
                                class="h-full w-full rounded-md"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                No Alternate Product Found
            </div>
        </div>

        <transition name="fadeTransition">
            <Modal ref="moreInspirations" class="z-50">
                <template v-slot:header>
                    <h1 class="text-lg font-bold">More Inspirations</h1>
                </template>
                <template v-slot:body>
                    <div class="h-96 overflow-auto">
                        <div
                            v-if="moreInspirations.length != 0"
                            class="flex flex-wrap mt-5"
                        >
                            <div
                                v-for="inspiration in moreInspirations"
                                :key="'inspiration' + inspiration.id"
                                class="w-1/3 mb-4 pr-3"
                            >
                                <div class="bg-gray-500 rounded-lg">
                                    <img
                                        class="rounded-lg"
                                        :src="
                                            inspiration.image
                                                ? inspiration.image
                                                : require('assets/images/room.png')
                                        "
                                    />
                                </div>
                                <div>
                                    <p class="font-bold">
                                        {{ inspiration.room_type }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div v-else class="w-full h-full flex items-center">
                            <Loader :loaderType="loaderType" />
                        </div>
                    </div>
                </template>
            </Modal>
        </transition>
        <transition name="fadeTransition">
            <Modal ref="alternateProductModal" class="z-50">
                <template v-slot:header>
                    <h1 class="text-lg font-bold">Product Detail</h1>
                </template>

                <template v-slot:body>
                    <div class="flex mt-5">
                        <div class="w-2/4 h-96">
                            <img
                                class="h-full mx-auto"
                                :src="
                                    productDetail.data.image
                                        ? productDetail.data.image
                                        : require('assets/images/custom_scene/csproduct.jpg')
                                "
                            />
                        </div>
                        <div class="px-5 w-2/4">
                            <h1 class="text-sm font-bold">
                                {{ productDetail.data.name }}
                            </h1>
                            <p class="text-xs my-2">
                                {{ productDetail.data.description }}
                            </p>
                            <div v-if="productDetail.data.size">
                                <p class="mt-4 text-gray-400 text-xs font-bold">
                                    Size
                                </p>
                                <p class="text-sm">
                                    {{ productDetail.data.size }}
                                </p>
                            </div>
                            <p class="mt-4 text-gray-400 text-xs font-bold">
                                Sold by
                            </p>
                            <p class="text-sm">
                                {{ productDetail.data.vendor_name }} Inc.
                            </p>

                            <h1 class="font-bold text-lg mt-4">
                                &#8377;
                                {{
                                    convertToIndianNumberSystem(
                                        productDetail.data.item_price
                                            ? productDetail.data.item_price
                                            : productDetail.data.base_price,
                                    )
                                }}
                            </h1>
                            <!-- <Accordion  class="border-l-2 border-r-2" /> -->
                        </div>
                    </div>
                </template>
            </Modal>
        </transition>
    </div>
</template>
<script>
import { bus } from 'main'
import { mapState, mapGetters, mapActions } from 'vuex'
import ConvertToPrice from 'mixins/ConvertToPrice'
import { WAVE_LOADER } from 'utils/constants'

import Button from 'components/Button'
import Modal from 'components/Modal'
import CustomSelect from 'components/CustomSelect'
import Loader from 'componentsv2/Loader'
import _ from 'lodash'

export default {
    name: 'CustomSceneImageSection',
    components: {
        Button,
        Modal,
        CustomSelect,
        Loader,
    },
    mixins: [ConvertToPrice],
    props: {},
    data() {
        return {
            currentSceneIndex: -1,
            currentProductIndex: -1,
            currentSceneImageIndex: 0,
            currentSceneId: null,
            currentProductType: null,
            sceneImage: '', //current scene image to display
            scene_image_array: [], // store current scene images array
            currentSceneProducts: [], //current scene products
            allProductsInSet: [], //no use for now store all products from scene
            displayProductCart: [], //variable use to store all products from scene for display on modal
            replacedProduct: {}, //old product which was replaced by new
            loaderType: WAVE_LOADER,
        }
    },
    watch: {
        //make a copy of scene data for modification when scene_data change
        scene_data: function() {
            this.cloneData(this.scene_data)
        },

        //when clone of scene_data change
        clone_scene_data: function() {
            let allProducts = {}
            let cartProducts = {}
            this.clone_scene_data.forEach((scene, scene_index) => {
                scene.furnishing_products_in_scene.forEach(
                    (product, product_index) => {
                        if (allProducts[product.product_id]) {
                            allProducts[product.product_id].p_quantity++
                        } else {
                            allProducts[product.product_id] = {
                                p_quantity: 1,
                            }
                        }

                        if (cartProducts[product.product_id]) {
                            cartProducts[product.product_id].p_quantity++
                        } else {
                            cartProducts[product.product_id] = {
                                ...product,
                                p_quantity: 1,
                                scene_index: scene_index,
                                product_index: product_index,
                            }
                        }
                    },
                )

                scene.decor_products_in_scene.forEach(
                    (product, product_index) => {
                        if (allProducts[product.product_id]) {
                            allProducts[product.product_id].p_quantity++
                        } else {
                            allProducts[product.product_id] = {
                                p_id: product.product_id,
                                p_quantity: 1,
                            }
                        }

                        if (cartProducts[product.product_id]) {
                            cartProducts[product.product_id].p_quantity++
                        } else {
                            cartProducts[product.product_id] = {
                                ...product,
                                p_quantity: 1,
                                scene_index: scene_index,
                                product_index: product_index,
                            }
                        }
                    },
                )
            })
            this.allProductsInSet = allProducts
            this.displayProductCart = cartProducts
            this.$emit('modifiedSceneData', this.clone_scene_data)
        },
        displayProductCart: function() {
            this.CartProducts(this.displayProductCart)
        },
        // replacedProduct: function(){
        //     console.log('updated')
        //     this.replacedProducts(this.replacedProduct)
        // }
    },
    created() {
        this.cloneData(this.scene_data)
        bus.$on('removeProductCart', (product, isalternate) => {
            this.removeProduct(product, isalternate)
        })
    },
    computed: {
        ...mapState({
            scene_data: state =>
                state.CustomScenePageModule.customScenes.scene_data,
            clone_scene_data: state =>
                state.CustomScenePageModule.cloneSceneData,
            vt_url: state => state.CustomScenePageModule.customScenes.vt_url,
            alternateProducts: state =>
                state.CustomScenePageModule.alternateProducts,
            moreInspirations: state =>
                state.CustomScenePageModule.moreInspirations,
            totalCartValue: state => state.CustomScenePageModule.totalCartValue,
            productDetail: state => state.CustomScenePageModule.productDetail,
        }),
        ...mapGetters({
            totalCartProducts: 'totalCartProducts',
        }),
        defaultSelect() {
            let onLoadSelected = {}
            this.scene_data.forEach((scene, i) => {
                if (scene.id == this.$route.params.id) {
                    onLoadSelected.scene = scene
                    onLoadSelected.index = i
                }
            })
            return onLoadSelected
        },
    },
    methods: {
        ...mapActions({
            cloneData: 'cloneData',
            updateCloneData: 'updateCloneData',
            CartProducts: 'CartProducts',
            replacedProducts: 'replacedProducts',
            fetchAlternateProducts: 'fetchAlternateProducts',
            clearAlternateProducts: 'clearAlternateProducts',
            fetchMoreInspirations: 'fetchMoreInspirations',
            fetchUpdateScene: 'fetchUpdateScene',
            fetchProductDetail: 'fetchProductDetail',
            toggleCart: 'toggleCart',
        }),

        openVirtualTour(url) {
            window.open(url)
        },

        //function detect current displaying scene and change currentSceneProducts to display current product on image
        currentScene(value) {
            this.currentSceneIndex = value.index
            this.currentSceneId = this.clone_scene_data[value.index].id
            this.sceneImage = this.clone_scene_data[value.index].img_array[
                this.currentSceneImageIndex
            ]
            this.scene_image_array = this.clone_scene_data[
                this.currentSceneIndex
            ].img_array
            this.currentSceneProducts = [
                ...this.clone_scene_data[value.index]
                    .furnishing_products_in_scene,
                ...this.clone_scene_data[value.index].decor_products_in_scene,
            ]

            //check it alternate product array empty if not clear the array when changing next scene
            if (this.alternateProducts.data.length != 0) {
                this.clearAlternateProducts()
            }

            let products = []
            products.push(
                ...this.clone_scene_data[this.currentSceneIndex]
                    .furnishing_products_in_scene,
            )
            products.push(
                ...this.clone_scene_data[this.currentSceneIndex]
                    .decor_products_in_scene,
            )

            this.$emit('productInSceneChanged', products)
            this.$emit(
                'serviceInSceneChanged',
                this.clone_scene_data[this.currentSceneIndex].services_data,
            )
        },

        //function used when click on map product image to fetch alternative product
        alternateProduct(index, product_type, scene_id, x_loc, y_loc) {
            this.currentProductIndex = index
            this.currentProductType = product_type
            this.fetchAlternateProducts({
                scene_id: scene_id,
                x_loc: x_loc,
                y_loc: y_loc,
            })
        },

        changeProductInScene(
            newProduct,
            prevproducts,
            currentClickedProductIndex,
            currentSceneIndex,
        ) {
            let oldproduct = prevproducts[currentClickedProductIndex]
            let productsarray = []
            this.currentSceneProducts.forEach(product => {
                productsarray.push({
                    product_id: product.product_id,
                    x_loc: product.xperc,
                    y_loc: product.yperc,
                })
            })
            // window.open(`http://www.uat3d.rift.co.in/integrate/?scene_id=${this.currentSceneId}&replaced_pid=${oldproduct.product_id}&new_pid=${newProduct.product_id}`)
            let body = {
                scene_id: this.clone_scene_data[currentSceneIndex].id,
                products_in_scene: productsarray,
                be_replaced: this.currentSceneProducts[
                    currentClickedProductIndex
                ].product_id,
                replaced_with: newProduct.product_id,
            }

            //make api call to get new scene image with new products
            this.fetchUpdateScene({
                body: body,
                sceneIndex: currentSceneIndex,
                productIndex: currentClickedProductIndex,
            }).then(() => {
                this.currentSceneProducts = [
                    ...this.clone_scene_data[currentSceneIndex]
                        .furnishing_products_in_scene,
                    ...this.clone_scene_data[currentSceneIndex]
                        .decor_products_in_scene,
                ]

                this.fetchAlternateProducts({
                    scene_id: this.currentSceneId,
                    x_loc: this.currentSceneProducts[currentClickedProductIndex]
                        .xperc,
                    y_loc: this.currentSceneProducts[currentClickedProductIndex]
                        .yperc,
                })

                this.sceneImage = this.clone_scene_data[
                    currentSceneIndex
                ].img_array[0]

                if (this.replacedProduct[oldproduct.product_id]) {
                    console.log(
                        'already replaced but now new product is replaced',
                    )
                } else {
                    prevproducts[
                        currentClickedProductIndex
                    ].replaced_product_id = newProduct.product_id
                    this.replacedProduct[oldproduct.product_id] =
                        prevproducts[currentClickedProductIndex]
                }

                this.replacedProducts(this.replacedProduct)

                // let newScene = _.cloneDeep(this.clone_scene_data)
                // let newProductList = [...prevproducts]

                // newProductList[currentClickedProductIndex]={
                //     ...newProduct,
                //     replaced_product_id: oldproduct.product_id
                // }

                // newScene[currentSceneIndex].furnishing_products_in_scene=[]
                // newScene[currentSceneIndex].decor_products_in_scene=[]

                // newProductList.forEach((product)=>{
                //     if(product.product_type=="furnish"){
                //         newScene[currentSceneIndex].furnishing_products_in_scene.push(product)
                //     }else{
                //         newScene[currentSceneIndex].decor_products_in_scene.push(product)
                //     }
                // })

                // this.updateCloneData(newScene)

                let products = []
                products.push(
                    ...this.clone_scene_data[currentSceneIndex]
                        .furnishing_products_in_scene,
                )
                products.push(
                    ...this.clone_scene_data[currentSceneIndex]
                        .decor_products_in_scene,
                )

                this.$emit('productInSceneChanged', products)
            })
        },

        // this function was used by trash icon on current scene product to remove product from scene
        removeProduct(index, product_id, product_type) {
            console.log(index, 'index', product_id)
            if (product_type == 'furnish') {
                let temp = _.cloneDeep(this.clone_scene_data)
                _.remove(
                    temp[this.currentSceneIndex].furnishing_products_in_scene,
                    function(product) {
                        return product.product_id == product_id
                    },
                )
                console.log(temp)
                this.updateCloneData(temp)
            } else {
                let temp = _.cloneDeep(this.clone_scene_data)
                _.remove(
                    temp[this.currentSceneIndex].decor_products_in_scene,
                    function(product) {
                        return product.product_id == product_id
                    },
                )
                console.log(temp)
                this.updateCloneData(temp)
            }
            if (this.alternateProducts.data.length != 0) {
                this.clearAlternateProducts()
            }
            this.currentSceneProducts = [
                ...this.clone_scene_data[this.currentSceneIndex]
                    .furnishing_products_in_scene,
                ...this.clone_scene_data[this.currentSceneIndex]
                    .decor_products_in_scene,
            ]
        },

        //this function run on click of camera icon to change scene viewing angle
        changeView() {
            var imgarray = this.clone_scene_data[this.currentSceneIndex]
                .img_array
            if (this.currentSceneImageIndex < imgarray.length - 1) {
                this.currentSceneImageIndex++
            } else {
                this.currentSceneImageIndex = 0
            }

            this.sceneImage = this.clone_scene_data[
                this.currentSceneIndex
            ].img_array[this.currentSceneImageIndex]
            if (this.currentSceneImageIndex == 0) {
                this.currentSceneProducts = [
                    ...this.clone_scene_data[this.currentSceneIndex]
                        .furnishing_products_in_scene,
                    ...this.clone_scene_data[this.currentSceneIndex]
                        .decor_products_in_scene,
                ]
            } else {
                this.currentSceneProducts = []
            }
        },

        moreInspirationsModal() {
            this.moreInspirations = []
            this.$refs.moreInspirations.openModal()
            this.fetchMoreInspirations(this.currentSceneId)
        },

        locProductModal(product_id) {
            this.$refs.alternateProductModal.openModal()
            this.fetchProductDetail(product_id)
        },

        hideAlternateProduct() {
            this.clearAlternateProducts()
        },
        // not in use now
        //indicate to if alternate product is selected
        // isSelected(id){
        //     return this.currentSceneProducts[this.currentProductIndex].product_id == id
        // }
    },
}
</script>
<style lang="scss" scoped>
/* .alternate-products_section{

} */
.alternateproduct-hide {
    align-self: center;
    margin: 10px;
    cursor: pointer;
    color: red;
    font-size: 30px;
}
.fadeTransition-enter-active,
.fadeTransition-leave-active {
    transition: opacity 0.5s;
}
.fadeTransition-enter, .fadeTransition-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.circle-add {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    margin: 0;
    border: 1px solid white;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;

    .add {
        width: 25px;
        height: 25px;
        margin: 0;
        font-size: 1.8rem;
        color: #ff6100;
        background: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.circle-outer {
    &:hover > .circle-add-menu {
        display: flex;
        background-color: white;
        border-radius: 40px;
        padding: 5px;
        & > * {
            cursor: pointer;
            margin: 5px 10px;
            color: #ff6100;
        }
    }
}
.circle-add-menu {
    display: none;
    transition: all 2s ease;
}
</style>
