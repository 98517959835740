var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.theme_data)?_c('div',[_c('div',{staticClass:"px-20 py-20 w-full"},[_c('h1',{staticClass:"text-2xl font-bold w-full mb-10"},[_vm._v(" Package Detail ")]),_c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"w-2/5"},[_c('p',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.theme_data.name)+" ")]),_c('p',{staticClass:"text-justify"},[_vm._v(" "+_vm._s(_vm.theme_data.description)+" ")])]),(_vm.serviceInScene.length != 0)?_c('div',{staticClass:"w-3/5 pl-20 pr-2"},[_vm._m(0),_c('Accordion',{staticClass:"border-l-2 border-r-2",attrs:{"content":_vm.serviceInScene,"defaultActive":0}})],1):_vm._e()]),(_vm.currentSceneProducts.length > 0)?_c('div',{staticClass:"my-14"},[_c('h1',{staticClass:"text-2xl font-bold"},[_vm._v(" Products in cart ("+_vm._s(this.currentSceneProducts.length)+") ")]),_c('div',{staticClass:"flex flex-row flex-wrap"},_vm._l((_vm.currentSceneProducts),function(product,index){return _c('div',{key:index + '_' + product.product_id,staticClass:"w-1/4 mt-10 pr-2 cursor-pointer",on:{"click":function($event){return _vm.productModal(product.product_id)}}},[_c('Box',{staticClass:"box-carousel",attrs:{"img":product.image,"heading":product.name,"hSizeLg":"","subHeading":'₹ ' +
                                _vm.convertToIndianNumberSystem(
                                    product.item_price
                                        ? product.item_price
                                        : product.base_price
                                ),"shBold":"","SBox":""}})],1)}),0)]):_vm._e()]),_c('transition',{attrs:{"name":"productdetail"}},[_c('Modal',{ref:"productModal",staticClass:"z-50",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',{staticClass:"text-lg font-bold"},[_vm._v("Product Detail")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"flex mt-5"},[_c('div',{staticClass:"w-2/4 h-96"},[_c('img',{staticClass:"h-full mx-auto",attrs:{"src":_vm.productDetail.data.image
                                    ? _vm.productDetail.data.image
                                    : require('../../assets/images/custom_scene/csproduct.jpg')}})]),_c('div',{staticClass:"px-5 w-2/4"},[_c('h1',{staticClass:"text-sm font-bold"},[_vm._v(" "+_vm._s(_vm.productDetail.data.name)+" ")]),_c('p',{staticClass:"text-xs my-2"},[_vm._v(" "+_vm._s(_vm.productDetail.data.description)+" ")]),(_vm.productDetail.data.size)?_c('div',[_c('p',{staticClass:"mt-4 text-gray-400 text-xs font-bold"},[_vm._v(" Size ")]),_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.productDetail.data.size)+" ")])]):_vm._e(),_c('p',{staticClass:"mt-4 text-gray-400 text-xs font-bold"},[_vm._v(" Sold by ")]),_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm.productDetail.data.vendor_name)+" Inc. ")]),_c('h1',{staticClass:"font-bold text-lg mt-4"},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.productDetail.data.item_price ? _vm.productDetail.data.item_price : _vm.productDetail.data.base_price ))+" ")])])])]},proxy:true}],null,false,1018770010)})],1)],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pl-5 py-2 bg-gray-200 border-b-2 border-gray-400"},[_c('h1',{staticClass:"font-bold"},[_vm._v("Services")])])])}]

export { render, staticRenderFns }