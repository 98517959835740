<template>
    <div v-if="theme_data">
        <div class="px-20 py-20 w-full">
            <h1 class="text-2xl font-bold w-full mb-10">
                Package Detail
            </h1>
            <div class="flex w-full">
                <div class="w-2/5">
                    <p class="font-bold">
                        {{ theme_data.name }}
                    </p>
                    <p class="text-justify">
                        {{ theme_data.description }}
                    </p>
                </div>
                <div class="w-3/5 pl-20 pr-2" v-if="serviceInScene.length != 0">
                    <div>
                        <div
                            class="pl-5 py-2 bg-gray-200 border-b-2 border-gray-400"
                        >
                            <h1 class="font-bold">Services</h1>
                        </div>
                    </div>
                    <Accordion
                        class="border-l-2 border-r-2"
                        :content="serviceInScene"
                        :defaultActive="0"
                    />
                </div>
            </div>
            <div class="my-14" v-if="currentSceneProducts.length > 0">
                <h1 class="text-2xl font-bold">
                    Products in cart ({{ this.currentSceneProducts.length }})
                </h1>
                <div class="flex flex-row flex-wrap">
                    <div
                        v-for="(product, index) in currentSceneProducts"
                        :key="index + '_' + product.product_id"
                        class="w-1/4 mt-10 pr-2 cursor-pointer"
                        @click="productModal(product.product_id)"
                    >
                        <Box
                            class="box-carousel"
                            :img="product.image"
                            :heading="product.name"
                            hSizeLg
                            :subHeading="
                                '₹ ' +
                                    convertToIndianNumberSystem(
                                        product.item_price
                                            ? product.item_price
                                            : product.base_price,
                                    )
                            "
                            shBold
                            SBox
                        />
                    </div>
                </div>
            </div>
        </div>
        <transition name="productdetail">
            <Modal ref="productModal" class="z-50">
                <template v-slot:header>
                    <h1 class="text-lg font-bold">Product Detail</h1>
                </template>

                <template v-slot:body>
                    <div class="flex mt-5">
                        <div class="w-2/4 h-96">
                            <img
                                class="h-full mx-auto"
                                :src="
                                    productDetail.data.image
                                        ? productDetail.data.image
                                        : require('../../assets/images/custom_scene/csproduct.jpg')
                                "
                            />
                        </div>
                        <div class="px-5 w-2/4">
                            <h1 class="text-sm font-bold">
                                {{ productDetail.data.name }}
                            </h1>
                            <p class="text-xs my-2">
                                {{ productDetail.data.description }}
                            </p>
                            <div v-if="productDetail.data.size">
                                <p class="mt-4 text-gray-400 text-xs font-bold">
                                    Size
                                </p>
                                <p class="text-sm">
                                    {{ productDetail.data.size }}
                                </p>
                            </div>
                            <p class="mt-4 text-gray-400 text-xs font-bold">
                                Sold by
                            </p>
                            <p class="text-sm">
                                {{ productDetail.data.vendor_name }} Inc.
                            </p>

                            <h1 class="font-bold text-lg mt-4">
                                &#8377;
                                {{
                                    convertToIndianNumberSystem(
                                        productDetail.data.item_price
                                            ? productDetail.data.item_price
                                            : productDetail.data.base_price,
                                    )
                                }}
                            </h1>
                            <!-- <Accordion  class="border-l-2 border-r-2" /> -->
                        </div>
                    </div>
                </template>
            </Modal>
        </transition>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ConvertToPrice from 'mixins/ConvertToPrice'

import Box from 'components/Box'
import Accordion from 'components/Accordion'
import Modal from 'components/Modal'

export default {
    name: 'CustomSceneDetailSection',
    components: {
        Accordion,
        Modal,
        Box,
    },
    mixins: [ConvertToPrice],
    props: {
        currentSceneProducts: {
            type: Array,
        },
        serviceInScene: {
            type: Array,
        },
    },
    data() {
        return {
            productDetailAccordion: [],
        }
    },
    watch: {
        productDetail: function() {
            if (Object.keys(this.productDetail).length != 0) {
                console.log(this.productDetail)
                this.productDetailAccordion = [
                    {
                        name: 'Product Info',
                        description: this.productDetail.data.description,
                    },
                ]
            }
        },
    },
    computed: {
        ...mapState({
            theme_data: state =>
                state.CustomScenePageModule.customScenes.theme_data,
            productDetail: state => state.CustomScenePageModule.productDetail,
        }),
    },
    methods: {
        ...mapActions({
            fetchProductDetail: 'fetchProductDetail',
            clearProductDetail: 'clearProductDetail',
        }),
        productModal(product_id) {
            this.$refs.productModal.openModal()
            // this.clearProductDetail()
            this.fetchProductDetail(product_id)
        },
    },
}
</script>
<style lang="scss" scoped>
.productdetail-enter-active,
.productdetail-leave-active {
    transition: opacity 0.5s;
}
.productdetail-enter, .moreinspirations-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
