<template>
    <div class="border-l-2 border-r-2" role="presentation">
        <AccordionItem
            v-for="(item, index) in content"
            :multiple="multiple"
            :item="item"
            :groupId="groupId"
            :key="item.type+'_'+index"
            :active="index===defaultActive?true:false"
            :subContent="item.list_of_services?item.list_of_services:''"
        />
    </div>
</template>
<script>
import AccordionItem from 'components/AccordionItem'
export default {
    name: 'Accordion',
    components:{
        AccordionItem
    },
    props: {
        content: {
            type: Array,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        defaultActive:{
            type: Number,
            default: null
        }
    },
    data() {
        return {
            groupId: null,
        }
    },
    mounted() {
        this.groupId = this.$el.id
    }
}
</script>