<template>
    <div class="modal fixed top-0" v-if="show">
        <transition name="modal">
            <div
                class="modal__backdrop fixed top-0 w-screen h-screen flex justify-center items-center"
                @click="closeModal"
            >
                <div
                    class="modal__dialog relative w-2/4 mx-auto bg-white p-5 rounded-lg"
                    @click.stop
                >
                    <svg
                        class="h-5 absolute top-5 right-5 text-gray-500 cursor-pointer"
                        @click="closeModal"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    <div class="modal__header">
                        <slot name="header" />
                    </div>

                    <div class="modal__body">
                        <slot name="body" />
                    </div>

                    <div class="modal__footer">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </transition>
        nasjdaljshdlshdlksdlsk
    </div>
</template>
<script>
export default {
    name: 'Modal',
    data() {
        return {
            show: false,
        }
    },
    methods: {
        closeModal() {
            this.show = false
            document.querySelector('body').classList.remove('overflow-hidden')
        },
        openModal() {
            this.show = true
            document.querySelector('body').classList.add('overflow-hidden')
        },
    },
}
</script>
<style lang="scss" scoped>
.modal {
    &__backdrop {
        background-color: rgba(0, 0, 0, 0.3);
        transition: opacity 0.3s ease;
    }
    &__dialog {
        transition: all 0.3s ease;
    }
}

.modal-enter,
.modal-leave {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
