<template>
    <div>
        <div class="customscene-page" v-if="customScenes.length != 0">
            <div v-if="customScenes.status == false">
                <LoginPopup ref="loginPopup" @login="doLogin" />
            </div>
            <div v-if="customScenes.status != false">
                <CustomSceneImageSection
                    @productInSceneChanged="changeInProducts"
                    @modifiedSceneData="modifiedSceneDataAssign"
                    @serviceInSceneChanged="serviceInSceneChanged"
                />
                <CustomSceneDetailSection
                    :currentSceneProducts="currentSceneProducts"
                    :serviceInScene="serviceInScene"
                />
            </div>
        </div>
        <div v-else>
            <Loader
                class="absolute"
                style="top: 50%; left: 50%; transform: translate(-50%,-50%);"
                :loaderType="loaderType"
            />
        </div>
        <Footer />
        <transition name="cartshow">
            <div class="cart fixed top-0 z-50 " v-if="showCart">
                <div
                    class="cart__backdrop fixed top-0 w-screen h-screen"
                    @click="toggleCart(false)"
                >
                    <transition name="bounce">
                        <div
                            v-if="showCart"
                            class="cart__dialog absolute top-0 right-0 w-1/3 h-full bg-white z-10"
                            @click.stop
                        >
                            <svg
                                class="h-8 absolute top-5 right-5 text-gray-500 cursor-pointer"
                                @click="toggleCart(false)"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            <div class="cart__header px-5 pt-5">
                                <h1 class="text-xl font-bold">
                                    My cart ({{ totalCartProducts }})
                                </h1>
                            </div>
                            <div
                                class="cart__body mt-10 mb-10 px-5 overflow-y-auto"
                            >
                                <div
                                    v-for="product in cartProducts"
                                    :key="product.product_id"
                                >
                                    <div v-if="product.replaced_product_id">
                                        <div
                                            v-for="oldproduct in replacedProducts"
                                            :key="oldproduct.product_id"
                                        >
                                            <div
                                                v-if="
                                                    product.product_id ==
                                                        oldproduct.replaced_product_id
                                                "
                                            >
                                                <div
                                                    class="flex text-xs space-x-2 text-gray-500"
                                                >
                                                    <div
                                                        class="h-16 w-16 border-2 border-gray-200 rounded-md m-auto"
                                                    >
                                                        <img
                                                            class="h-full w-full rounded-md oldproduct-image"
                                                            :src="
                                                                oldproduct.image
                                                                    ? oldproduct.image
                                                                    : require('assets/images/custom_scene/bitmap.png')
                                                            "
                                                        />
                                                    </div>
                                                    <div
                                                        class="line-through p-2 flex flex-col justify-between flex-1"
                                                    >
                                                        <p>
                                                            {{
                                                                oldproduct.name
                                                            }}
                                                        </p>
                                                        <p
                                                            class="mt-5 font-bold"
                                                        >
                                                            &#8377;
                                                            {{
                                                                convertToIndianNumberSystem(
                                                                    oldproduct.item_price
                                                                        ? oldproduct.item_price
                                                                        : oldproduct.base_price,
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div
                                                        class="p-2 flex flex-col justify-between text-right"
                                                    >
                                                        <p>
                                                            x{{
                                                                product.p_quantity
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="flex text-xs space-x-2 my-4 relative ml-10"
                                        >
                                            <div
                                                class="h-14 w-7 absolute -top-6 -left-4"
                                            >
                                                <img
                                                    class="w-full h-full"
                                                    src="@/assets/images/custom_scene/replace.png"
                                                />
                                            </div>
                                            <div
                                                class="h-16 w-16 border-2 border-gray-200 rounded-md m-auto"
                                            >
                                                <img
                                                    class="h-full w-full rounded-md"
                                                    :src="
                                                        product.image
                                                            ? product.image
                                                            : require('../../assets/images/custom_scene/bitmap.png')
                                                    "
                                                />
                                            </div>
                                            <div
                                                class="p-2 flex flex-col justify-between flex-1"
                                            >
                                                <p>{{ product.name }}</p>
                                                <p class="mt-5 font-bold">
                                                    &#8377;
                                                    {{
                                                        convertToIndianNumberSystem(
                                                            product.item_price
                                                                ? product.item_price
                                                                : product.base_price,
                                                        )
                                                    }}
                                                </p>
                                            </div>
                                            <div
                                                class="p-2 flex flex-col justify-between text-right"
                                            >
                                                <p>x{{ product.p_quantity }}</p>
                                                <img
                                                    class="cursor-pointer"
                                                    @click="
                                                        removeThroughCart(
                                                            product,
                                                            true,
                                                        )
                                                    "
                                                    src="@/assets/images/custom_scene/ic-delete-copy.png"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        class="flex text-xs space-x-2 my-4"
                                    >
                                        <div
                                            class="h-16 w-16 border-2 border-gray-200 rounded-md"
                                        >
                                            <img
                                                class="h-full w-full rounded-md m-auto"
                                                :src="
                                                    product.image
                                                        ? product.image
                                                        : require('../../assets/images/custom_scene/bitmap.png')
                                                "
                                            />
                                        </div>
                                        <div
                                            class="p-2 flex flex-col flex-1 justify-between"
                                        >
                                            <p>{{ product.name }}</p>
                                            <p class="mt-5 font-bold">
                                                &#8377;
                                                {{
                                                    convertToIndianNumberSystem(
                                                        product.item_price
                                                            ? product.item_price
                                                            : product.base_price,
                                                    )
                                                }}
                                            </p>
                                        </div>
                                        <div
                                            class="p-2 flex flex-col justify-between text-right"
                                        >
                                            <p>x{{ product.p_quantity }}</p>
                                            <img
                                                class="cursor-pointer"
                                                @click="
                                                    removeThroughCart(
                                                        product,
                                                        false,
                                                    )
                                                "
                                                src="@/assets/images/custom_scene/ic-delete-copy.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="cart_footer bg-gray-100 w-full p-5 text-sm"
                            >
                                <div class="flex justify-between mb-5">
                                    <p class="text-gray-500">
                                        Total Cart Value
                                    </p>
                                    <p class="text-gray-700 font-semibold">
                                        &#8377;
                                        {{
                                            convertToIndianNumberSystem(
                                                totalCartValue,
                                            )
                                        }}
                                    </p>
                                </div>
                                <router-link
                                    :to="{
                                        name: 'FinaliseAndPlaceOrderPage',
                                        query: { ehd_id: $route.query.ehd_id },
                                    }"
                                >
                                    <Button class="w-full">
                                        Proceed to Checkout
                                    </Button>
                                </router-link>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { bus } from 'main'
import { mapState, mapGetters, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import CustomScenePageModule from 'store/modules/CustomScenePage'
import ConvertToPrice from 'mixins/ConvertToPrice'
import CustomSceneImageSection from 'components/CustomSceneImageSection'
import CustomSceneDetailSection from 'components/CustomSceneDetailSection'
import Button from 'components/Button'
import Loader from 'componentsv2/Loader'
import LoginPopup from 'components/LoginPopup'
import Footer from 'componentsv2/Footer'
import { WAVE_LOADER } from 'utils/constants'

export default {
    name: 'CustomScenePage',
    components: {
        Button,
        CustomSceneImageSection,
        CustomSceneDetailSection,
        Loader,
        LoginPopup,
        Footer,
    },
    props: {},
    mixins: [RegisterStoreModule, ConvertToPrice],
    data() {
        return {
            currentSceneProducts: [],
            modifiedSceneData: [],
            serviceInScene: [],
            loaderType: WAVE_LOADER,
        }
    },
    computed: {
        ...mapState({
            customScenes: state => state.CustomScenePageModule.customScenes,
            cartProducts: state => state.CustomScenePageModule.cartProducts,
            replacedProducts: state =>
                state.CustomScenePageModule.replacedProducts,
            testimonialList: state => state.AppModule.testimonialList,
            totalCartValue: state => state.CustomScenePageModule.totalCartValue,
            showCart: state => state.CustomScenePageModule.showCart,
            user: state => state.AuthModule.user,
        }),
        ...mapGetters({
            totalCartProducts: 'totalCartProducts',
        }),
    },
    watch: {},
    created() {
        console.log(this.$route)
        this.registerStoreModule('CustomScenePageModule', CustomScenePageModule)
        this.fetchCustomScene(this.$route.params.id)
        bus.$emit('ChangeNavBar', true)
    },
    destroyed() {
        this.$store.unregisterModule('CustomScenePageModule')
    },
    methods: {
        ...mapActions({
            fetchCustomScene: 'fetchCustomScene',
            toggleCart: 'toggleCart',
            calTotalCartValue: 'calTotalCartValue',
            login: 'login',
        }),

        changeInProducts(newProductList) {
            this.currentSceneProducts = newProductList
        },
        serviceInSceneChanged(serviceInSceneChanged) {
            this.serviceInScene = serviceInSceneChanged
        },
        modifiedSceneDataAssign(modifiedSceneData) {
            this.modifiedSceneData = modifiedSceneData
        },

        removeThroughCart(product, isalternate) {
            console.log('remove', product, isalternate)
            bus.$emit('removeProductCart', product, isalternate)
        },

        doLogin(data) {
            this.login(data).then(() => {
                if (this.user.status == true) {
                    this.fetchCustomScene(this.$route.params.id)
                    this.$refs.loginPopup.enableButton(true)
                    this.$refs.loginPopup.showError(null)
                } else {
                    this.$refs.loginPopup.enableButton(false)
                    this.$refs.loginPopup.showError(this.user.data.msg)
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.cart {
    &__backdrop {
        background-color: rgba(0, 0, 0, 0.3);
    }

    &__dialog {
        transition: transform 0.5s;
        display: flex;
        flex-direction: column;
    }
    .oldproduct-image {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
}

.cartshow-enter-active {
    transition: all 0.6s ease;
}
.cartshow-leave-active {
    transition: all 0.6s ease;
}
.cartshow-enter, .cartshow-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(700px);
    opacity: 0;
}
.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
.error {
    font-size: 30px;
    text-align: center;
}
</style>
