<template>
    <div class="loginpopup-wrapper">
        <div class="loginpopup">
            <h1 class="loginpopup__header">Login to customize scene</h1>
            <div class="loginpopup__inputgroup">
                <input type="text" placeholder="Email" v-model="email" @blur="validateEmail"/>
                <p class="error" v-if="emailError">{{emailError}}</p>
                <input type="password" placeholder="Password" v-model="password" @blur="validatePassword"/>
                <p class="error" v-if="passwordError">{{passwordError}}</p>
                <button type="submit" class="loginpopup__button" :class="{disabled: disabled}" @click="login">Login</button>
                <p class="error" v-if="formError">{{formError}}</p>
            </div>
        </div>
    </div>
</template>

<script>
const emailReg = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export default {
    name:'LoginPopup',
    components:{
    },
    data(){
        return {
            email: '',
            password: '',
            emailError: null,
            passwordError: null,
            disabled: false,
            formError: null,
        }
    },
    computed(){

    },
    created(){
        document.querySelector('body').classList.add('overflow-hidden')
    },
    destroyed(){
        document.querySelector('body').classList.remove('overflow-hidden')
    },
    methods:{
        showError(errorMessage){
            this.formError = errorMessage
        },
        enableButton(boolean){
            this.disabled = boolean
        },
        validateEmail(){
            return this.email === ''
                ? (this.emailError = 'Email is empty')
                : emailReg.test(this.email)
                ? (this.emailError = null)
                : (this.emailError = 'Email is not valid')
        },
        validatePassword(){
            return this.password === ''
                ? (this.passwordError = 'Password is empty')
                : (this.passwordError = '')
                // this.password.length >= 8
                // ? (this.passwordError = '')
                // : (this.passwordError =
                //       'Password should be minimum 8 characters')
        },

        login(){
            if(!this.validateEmail() || !this.validatePassword()){
                let params = {
                    email: this.email,
                    password: this.password
                }
                this.$emit('login', params)
            }
        }
    }
}
</script>

<style lang="scss">
@import "./LoginPopup.scss";
</style>