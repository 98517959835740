<template>
    <div class="box__container-wrapper">
        <div
            class="box__img-wrapper"
            :class="{
                'box__img-wrapper--h': RBox,
                'box__img-wrapper--v': SBox,
                circle: rounded,
            }"
        >
            <img
                class="box__img"
                :class="{ circle: rounded }"
                :src="
                    img
                        ? img
                        : require('../../assets/images/defaults/default_product.jpg')
                "
            />
        </div>
        <div class="box__body" :class="{ alignCenter: alignCenter }">
            <h1
                v-if="heading"
                class="box__body-heading"
                :class="{
                    'box__body-heading--bold': hBold,
                    'box__body-heading--lg': hSizeLg,
                    'box__body-heading--sm': hSizeSm,
                }"
            >
                {{ heading }}
            </h1>
            <p
                v-if="subHeading"
                class="box__body-subheading"
                :class="{ 'box__body-subheading--sm': shSizeSm }"
            >
                <span
                    v-if="prefix"
                    class=""
                    :class="{ 'box__body-subheading--bold': pBold }"
                    >{{ prefix }}
                </span>
                <span
                    v-if="subHeading"
                    :class="{ 'box__body-subheading--bold': shBold }"
                    >{{ subHeading }}</span
                >
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Box',
    props: {
        routeObject: {
            type: Object,
            default: () => {},
        },
        SBox: {
            type: Boolean,
        },
        RBox: {
            type: Boolean,
        },
        rounded: {
            type: Boolean,
        },
        img: {
            type: String,
        },
        alignCenter: {
            type: Boolean,
        },
        heading: {
            type: String,
        },
        hSizeLg: {
            type: Boolean,
        },
        hSizeSm: {
            type: Boolean,
        },
        hBold: {
            type: Boolean,
        },
        prefix: {
            type: String,
        },
        pBold: {
            type: Boolean,
        },
        subHeading: {
            type: String,
        },
        shSizeSm: {
            type: Boolean,
        },
        shBold: {
            type: Boolean,
        },
        targetBlank: {
            type: Boolean,
        },
    },
    data() {
        return {}
    },
}
</script>
<style lang="scss">
@import './Box.scss';
</style>
