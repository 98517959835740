<template>
    <button class="button-old py-3 px-8 font-extrabold text-white rounded">
        <slot>
        </slot>
    </button>
</template>
<script>
export default {
    name: 'Button',
}
</script>
<style lang="scss">
@use 'styles';
.button-old{
    background-color: styles.$primary;
}
</style>
